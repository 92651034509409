import React, { useRef ,useState} from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from 'react-router-dom'
function App() {
  const [to_name, setto_name] = useState()
  const [reply_to, setreply_to] = useState()
  const [subject, setSubject] = useState()
  const [message, setmessage] = useState()

  const form = useRef();
  const notify = () => toast('Your Mail sent successfully!');
  const sendEmail = (e, req) => {
   
    e.preventDefault();

    emailjs.sendForm('service_uxr5dqa', 'template_bb8niov', form.current, '_6pbelSyMXHWMEVVP')
      .then((result) => {
        console.log(result.text);
        setto_name('')
        setreply_to('')
        setSubject('')
        setmessage('')
        notify()
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <div className='backimag'>
      <section id='banner' className='banner'>
        <div className='bg-color'>
          <nav className='navbar navbar-default navbar-fixed-top'>
            <div className='container'>
              <div className='col-md-12'>
                <div className='navbar-header'>
                  <button type='button' className='navbar-toggle' data-toggle='collapse' data-target='#myNavbar'>
                    <span className='icon-bar'></span>
                    <span className='icon-bar'></span>
                    <span className='icon-bar'></span>
                  </button>
                  <a className='navbar-brand' href='#banner'><img src='assets/img/MyTurf Logo Full.png' className='img-responsive logoimge' alt='' /></a>
                </div>
                <div className='collapse navbar-collapse navbar-right' id='myNavbar'>
                  <ul className='nav navbar-nav'>
                    <li className='active'><a href='#banner'>Home</a></li>
                    <li className=''><a href='#service'>Services</a></li>
                    <li className=''><a href='#contact'>Contact</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          <div className='container'>
            <div className='row'>
              <div className='banner-info'>
                <div className='banner-text text-center'>
                  <h1 className='white'>MYTURF</h1>
                  <h1 className='white'>Book & play!!</h1>
                  <p>Indore's first and fastest turf booking app</p>
                  <img src='assets/img/barcode_22feb.png' className='img-responsive255' alt='' />
                  <div className='row'>
                    <div className='buttoncss'>
                    <a target='_blank' href='https://play.google.com/store/apps/details?id=com.turf_booking.myturf'><img src='assets/img/play_store_final.png' className='palystorecss' alt='' /></a>
                    <a target='_blank' href='https://apps.apple.com/sg/app/myturf-app/id6446388347'><img src='assets/img/app_store_final.png' className='applestore' alt='' /></a>
                    </div>
                  </div>
                 
                </div>
                <div className='overlay-detail text-center'>
                  <a href='#about'><i className='fa fa-angle-down'></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='service' className='section-padding sss' >
        <div className='container'>
          <div className='row'>
            <div className='card col-md-4 col-sm-4 '>
              <div className='card_body text-center'>
                <div className='icon text-white1'>
                  <i className='fa fa-search text-white1'></i>
                </div>
                <div className='icon-info'>
                  <h4 className='text-white1'>Search</h4>
                  <p className='text-white1'>Are you looking to play after work, organize your Sunday Five's football match? So Explore the largest network of sports facilities whole over the India.</p>
                </div>
              </div>
            </div>
            <div className='card col-md-4 col-sm-4'>
              <div className='card_body text-center'>
                <div className='icon text-white1'>
                  <i className='fa fa-address-book-o '></i>
                </div>
                <div className='icon-info'>
                  <h4 className='text-white1'>Book</h4>
                  <p className='text-white1'>Once you’ve found the perfect ground, court Connect with the venue through the Book Now Button to make online booking & secure easier payment.</p>
                </div>
              </div>
            </div>
            <div className='card col-md-4 col-sm-4'>
              <div className='card_body text-center'>
                <div className='icon text-white1'>
                  <i className='fa fa-futbol-o'></i>
                </div>
                <div className='icon-info'>
                  <h4 className='text-white1'>Play</h4>
                  <p className='text-white1'>You’re the hero, you’ve found a stunning turf or court, booked with ease and now its time to play. The scene is set for your epic match.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section id='about' className='section-padding about'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 col-sm-6 '>
              <div className='section-title about_title'>
                <h4 className='aboutclass'>Meet Your pals</h4>
                <p className='sec-para'><p>Want to play games ?<br />But don't get an opponent team?<br />
                  You can Invite a team or Join a pre scheduled match Through MyTurf</p>
                </p>
              </div>
            </div>
            <div className='col-md-6 col-sm-4'>
              <div className=' more-features-box text-center'>
                <img src='assets/img/meet-pals.png' className='img-responsive2' alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='playcoins-sec'>
        <div className='container'>
          <div className='container-fluid pd-none'>
            <div className='row row-eq-height'>
              <div className='col-md-6 d-none d-md-block'>
                <div className='text-sec'>
                  <div className='d-none'>
                    <h3 className='ltr-space wow animated fadeInUp slow animated' style={{ visibility: 'visible', animationName: 'fadeInUp' }}>Play More, Pay Less !</h3>
                    <h1 className='section-title COUPON_te ltr-space wow animated fadeInUp slow animated' style={{ visibility: 'visible', animationName: 'fadeInUp' }}>COUPON</h1>
                    <div className='content'>A digital wallet system with redeemable reward points</div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='img-sec'>
                  <div className='img-wrap'>
                    <img src='assets/img/offer.png' className='img-fluid wow  fadeInUp slow animated' alt='' style={{ visibility: 'visible', animationName: 'fadeInUp' }} />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      {/*<!--contact--> */}
      <section id='contact' className='section-padding'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h2 className='ser-title'>Contact us</h2>
              <hr className='botm-line' />
            </div>
            <div className='col-md-4 col-sm-4 Contact_Info'>
              <h3 className='cnt-ttl1'>Contact Info</h3>
              <div className='space'></div>
              <p><i className='fa fa-map-marker fa-fw pull-left fa-2x'></i>1310 SUDAMA NAGAR <br /> INDORE, 452009</p>
              <div className='space'></div>
              <p><i className='fa fa-envelope-o fa-fw pull-left fa-2x'></i>connect@myturf.online</p>
              <div className='space'></div>
              <p><i className='fa fa-phone fa-fw pull-left fa-2x'></i> +917581888848 <br/> +919662019159 (WhatsApp)<br /></p>
            </div>

            <div className='col-md-8 col-sm-8 marb20'>
              <div className='contact-info'>
                <h3 className='cnt-ttl'>Having Any Query! Or Book an appointment</h3>
                <div className='space'></div>
                <div id='sendmessage'>Your message has been sent. Thank you!</div>
                <div id='errormessage'></div>
                <form ref={form} onSubmit={sendEmail} className='contactForm'>
                  <div className='form-group'>
                    <input type='text' name='to_name' className='form-control br-radius-zero' id='to_name' placeholder='Your Name' data-rule='minlen:4' data-msg='Please enter at least 4 chars'  value={to_name} onChange={(e) => setto_name(e.target.value)}/>
                    <div className='validation'></div>
                  </div>
                  <div className='form-group'>
                    <input type='email' className='form-control br-radius-zero' name='reply_to' id='reply_to' placeholder='Your Email' data-rule='email' data-msg='Please enter a valid email'  value={reply_to} onChange={(e) => setreply_to(e.target.value)}/>
                    <div className='validation'></div>
                  </div>
                  <div className='form-group'>
                    <input type='text' className='form-control br-radius-zero' name='subject' id='subject' placeholder='Subject' data-rule='minlen:4' data-msg='Please enter at least 8 chars of subject'  value={subject} onChange={(e) => setSubject(e.target.value)}/>
                    <div className='validation'></div>
                  </div>
                  <div className='form-group'>
                    <textarea className='form-control br-radius-zero' name='message' rows='5' data-rule='required' data-msg='Please write something for us' placeholder='Message'  value={message} onChange={(e) => setmessage(e.target.value)}></textarea>
                    <div className='validation'></div>
                  </div>
                  <div className='form-action'>
                    <button type='submit' className='btn btn-form'>Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--/ contact-->
      <!--footer--> */}
      <footer id='footer'>
        <div className='top-footer'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-4 col-sm-4 marb20'>
                <img src='assets/img/MyTurf Logo Full.png' className='img-responsive logoimge' alt='' />

              </div>
              <div className='col-md-4 col-sm-4 marb20'>
                <div className='ftr-tle'>
                  <h4 className='white no-padding'>Links</h4>
                </div>
                <div className='info-sec'>
                  <ul className='quick-info'>
                    <li><a href='#banner'>Home</a></li>
                    <li><a href='#service'>Service</a></li>
                    <li><a href='#contact'>Contact</a></li>
                    <li><Link to='/about-us'>About Us</Link></li>
                    <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                    <li><Link to='/terms'>Terms & Conditions</Link></li>
                    <li><Link to='/refundpolicy'>Refund Policy</Link></li>
                    <li><Link to='/delete-user'>Delete User</Link></li>
                  </ul>
                </div>
              </div>
              <div className='col-md-4 col-sm-4 marb20'>
                <div className='ftr-tle'>
                  <h4 className='white no-padding'>Follow us</h4>
                </div>
                <div className='info-sec'>
                  <ul className='social-icon'>
                    <li className='bg_insta'><a target='_blank' href='https://instagram.com/myturf_app/'><i className='fa fa-instagram'></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-line'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 text-center'>
                © Copyright MYTURF. All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ToastContainer />
    </div>
  );
}

export default App;

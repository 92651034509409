import React, { useState } from 'react';
import axios, * as others from 'axios';
import ApiConstants from './constants/ApiConstants';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

const DeleteUser = () => {
    const [phonenumber, setPhonenumber] = useState('');
    const [currentOtp, setCurrentOtp] = useState(664488);
    const [otp, setOtp] = useState();
    const [showOtp, setShowOtp] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const navigate = useNavigate();

    const updatePhoneNumberHandler = (e) => {
        const strPhonenumber = e.target.value;
        setPhonenumber(strPhonenumber);
        setShowOtp(false);
        setDisableBtn(false);
    };
    const updateOtpHandler = (e) => {
        const strOtp = e.target.value;
        setOtp(strOtp);
    };
    const handleSendOTP = () => {
        try {
            let config = {
                method: 'get',
                url: ApiConstants.EXTERNAL_API + ApiConstants.SEND_OTP + '?mobile_num=' + phonenumber + '&otp_code=' + currentOtp,
                headers: {
                    Authorization: ApiConstants.AUTHORIZATION_KEY,
                },
            };
            axios
                .request(config)
                .then((response) => {
                    if (response) {
                        console.log(response);
                    }
                })
                .catch((error) => {
                    // setShowOtp(false);
                    setDisableBtn(false);
                    console.log(error);
                    alert(error);
                });
        } catch (error) {
            alert(error);
        }
    };

    const handleCheckUser = () => {
        try {
            var pattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
            if (!pattern.test(phonenumber)) {
                alert('Enter the valid phone number');
            } else {
                setDisableBtn(true);
                let data = {
                    user_id: phonenumber,
                    token: '985th9hberb49h49fhoirnojvn',
                };

                let config = {
                    method: 'post',
                    url: ApiConstants.INTERNAL_API + ApiConstants.CHECK_USER_EXISTS,
                    headers: {
                        Authorization: ApiConstants.AUTHORIZATION_KEY,
                        'Content-Type': 'application/json',
                    },
                    data: data,
                };

                axios
                    .request(config)
                    .then((response) => {
                        setDisableBtn(false);
                        if (response && response.status == 200) {
                            setShowOtp(true);
                            handleSendOTP();
                        } else {
                            alert('User does not exist');
                        }
                    })
                    .catch((error) => {
                        setDisableBtn(false);
                        alert(error);
                    });
            }
        } catch (error) {
            setDisableBtn(false);
            console.log(error);
        }
    };

    const handleDeleteUser = () => {
        try {
            if (otp == currentOtp) {
                setDisableBtn(false);
                let config = {
                    method: 'delete',
                    url: ApiConstants.INTERNAL_API + ApiConstants.DELETE_USER + '/' + phonenumber,
                    headers: {
                        Authorization: ApiConstants.AUTHORIZATION_KEY,
                    },
                };

                axios
                    .request(config)
                    .then((response) => {
                        if (response) {
                            console.log(response);
                            navigate('/');
                        }
                    })
                    .catch((error) => {
                        alert(error);
                    });
            } else {
                alert('Please enter the valid OTP');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleBackToHomePage = () => {
        navigate('/');
    };
    return (
        <div className='delete-user-root'>
            <div className='delete-user-basediv'>
                <div className='delete-user-innerdiv'>
                    <h2 className='title-text'>Delete Account</h2>
                    <p className='subtitle-text'>
                        Are you sure you want to permanently <b>delete your account?</b>
                    </p>
                    <div className='warning-holder'>
                        <svg className='warning-svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
                            <path fillRule='evenodd' d='M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z' clipRule='evenodd' />
                        </svg>
                        <div>
                            <h3 className='warning-title'>Warning</h3>
                            <p className='warning-subtitle'>By deleting this account you loss your all data related this account</p>
                        </div>
                    </div>
                    <div className='input-holder'>
                        <label className='label-text'>Registered Mobile Number</label>
                        <input id='phonenumber' name='phonenumber' value={phonenumber} placeholder='Enter your phone number' type='number' onChange={updatePhoneNumberHandler} className='input-box' />
                    </div>
                    {showOtp && (
                        <div className='input-holder'>
                            <label className='label-text'>Otp</label>
                            <input id='otp' name='otp' value={otp} placeholder='Enter otp' type='text' onChange={updateOtpHandler} className='input-box' />
                        </div>
                    )}
                    <button type='button' onClick={showOtp ? handleDeleteUser : handleCheckUser} className={'delete-btn'} style={{ pointerEvents: disableBtn ? 'none' : '' }}>
                        {'Delete Account'}
                    </button>
                    <button type='button' onClick={handleBackToHomePage} className={'cancel-btn'}>
                        {'Back to home page'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteUser;

import React from 'react';
function Refund() {
    return (
        <div>
            <div class="wrapper">
                <div class="page">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h1 className='h1heading'>Cancellations and Refund POLICY</h1>
                                <p className='ptag'>Cancellations are subject to the policy as set by the respective Turf Owner. You can view the cancellation policies of the respective merchant partner on their turf detail screen prior to making a booking.

                                    Cancellations can be initiated by the users themselves on your booking ticket. The refund amount due will be displayed prior to seeking confirmation of the cancellation. The refund amount will be credited back into the user’s account, to the same source through which the payment was made, within 5-7 working days, post initiating the cancellation.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Refund;

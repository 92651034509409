import React from 'react';
function Privacypolicy() {
    return (
        <div>
            <div class="wrapper">
                <div class="page">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h1 className='h1heading'>My Turf App</h1>
                                <p className='ptag'>MY TURF incorporation no. 1NDO220720SE009690 built the MyTurf app as a Commercial app. This SERVICE is provided by MY TURF and is intended for use as it is.</p>
                                <h2 className='h2heading'>Copyright and Intellectual Property</h2>
                                <p className='ptag'>All information displayed, transmitted or carried on MyTurf App is protected by copyright and other intellectual property laws. This App is designed, updated and maintained independently by MY TURF. You should not modify, publish, transmit, transfer, sell, reproduce, create derivative work from, distribute, perform, display or in any way commercially exploit any of the content on MyTurf App.</p>

                                <h2 className='h2heading'>Terms of Use - Disclaimer</h2>
                                <p className='ptag'>MyTurf reserves the right, in its sole discretion, to suspend or cancel the service at any time if a computer virus, bug, or other technical problem corrupts the security, or proper administration of the service. MyTurf disclaims all warranties or conditions, whether expressed or implied, (including without limitation implied, warranties or conditions of information and context). We consider ourselves and intend to be subject to the jurisdiction only of the local courts. MyTurf reserves the right to refuse service to anyone at any time. MyTurf shall not be responsible for any damage caused by the use of its app.</p>

                                <h2 className='h2heading'>Privacy Policy</h2>
                                <p className='ptag'>MyTurf, respect your privacy as much as you do. We do not share your personal information to outsiders other than the Turf Owners. This information is only used by MyTurf and it pertains to ensure the fulfilment of the service requested by you. This includes informing you of upcoming tournaments and offers. You can select to stop receiving the notification, however the information you provide shall remain archived in MyTurf servers. MyTurf may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</p>

                                <h2 className='h2heading'>Information we collect:-</h2>
                                <ul className='uldisc'>
                                    <li className='liui'>User Full Name</li>
                                    <li className='liui'>User Mobile. No. for sending OTP for Signing Up and Logging [if user logs out of the MyTurf app]</li>
                                    <li className='liui'>User Email Address</li>
                                    <li className='liui'>User Current Location to display the Turfs based on user current location</li>
                                </ul>

                                <h2 className='h2heading'>What we do with the information we gather.</h2>
                                <p className='ptag'>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons: Internal record keeping. We may use the information to improve our products and services. We may periodically send promotional push notifications about new tournaments, special offers or other information which we think you may find interesting using the push notification.
                                    From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone or mail. We may use the information to customize the app according to your interests.</p>

                                <h2 className='h2heading'>Security</h2>
                                <p className='ptag'>We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect via MyTurf App. Our system is hosted on Amazon Web Services Mumbai Region in and our servers are spanned across 3 AZ's. We have implemented all recommended security services to prevant the sytem from any kind of malicious attack.
                                    We are striving to use commercially acceptable means of protecting it and all the services used to design the backend system is on Amazon Web Services with encryption of data in transit. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, we cannot guarantee its absolute security.</p>

                                <h2 className='h2heading'>Sharing your personal information</h2>
                                <p className='ptag'>We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above. We may use third party service providers to help us operate our business and the Site or administer activities on our behalf. We may share your information with these third parties for those limited purposes provided that you have given us your permission.</p>

                                <h2 className='h2heading'>Changes to this privacy policy</h2>
                                <p className='ptag'>MyTurf has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>

                                <h2 className='h2heading'>Your acceptance of these terms</h2>
                                <p className='ptag'>You signify your acceptance of this policy. If you do not agree to this policy, please do not use our app. Your continued use of the app following the posting of changes to this policy will be deemed your acceptance of those changes.</p>

                                <h2 className='h2heading'>Contacting Us</h2>
                                <p className='ptag'>If you have any questions about this Privacy Policy, the practices of the MyTurf app, or your dealings with this site, please contact us at: connect@myturf.online</p>

                                <h4 className='h4heading'>Updated on:- 12/05/2023</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Privacypolicy;

import React from 'react';
function Terms() {
    return (
        <div>
            <div class="wrapper">
                <div class="page">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h1 className='h1heading'>TERMS of Use for the MyTurf App</h1>

                                <h2 className='h2heading'>Terms & Conditions</h2>
                                <p className='ptag'>The Terms and Conditions (defined below) govern your access and use of the MyTurf App (defined below), use of our services and the sale and purchase of Services from My Turf through the app.
                                    By using the MyTurf  (“the App”), you AGREE to be bound by CONDITIONS OF USE (set out below) and PRIVACY POLICY, all disclaimers and terms and conditions that appear on the Site (collectively “Terms and Conditions”) which form an integral part of the agreement between MyTurf and you on use of the App, our services provided and the sale and purchase of Services through the app. If you do not agree with the Terms and Conditions, please discontinue your use and access to the app immediately. You may also terminate this Agreement by written notice to us at any time. MyTurf App reserves the right to amend any provisions of the Terms and Conditions without prior notice. Your continued use of the app after such amendment shall be deemed acceptance of the amended Terms and Conditions.</p>

                                <h2 className='h2heading'>Condition of Use</h2>
                                <p className='ptag'>In the Terms and Conditions, "we" and "us" means MyTurf and "you" and “User(s)” means the person, firm or corporation who access and use the MyTurf App. Except to the extent that the subject matter or context may otherwise require in the Terms and Conditions, (i) expressions including the singular may indicate the plural and vice versa; (ii) expressions indicating any particular gender may indicate all other genders; (iii) expressions indicating bodies corporate may also indicate natural persons and vice versa; (iv) all references to Clauses are to the clauses of the CONDITIONS OF USE; and (v) the following expressions shall have their respective meanings set out as follows:
                                    "Acknowledgement" means our push notification acknowledgement of your Booking Order.
                                    "Agreement" means the agreement between MyTurf and you on use of the App, our services provided and and Services purchased through the App and which comprises of the Terms and Conditions, your Booking Order and the Booking Confirmation;
                                    “Associates” shall have the meaning set out in Clause 16.1;
                                    “Content” shall have the meaning set out in Clause 12.2;
                                    "Services" means booking slots that are booked through the app and which comprise of the several Turf Owners;
                                    “Guest” shall have the meaning set out in Clause 1.1;
                                    “Member” shall have the meaning set out in Clause 1.2;
                                    "Price" means the purchase price of the Services listed for booking in the app ;
                                    “Experience Provider” means any merchant that engages the services of MyTurf app to place listing(s) for the booking.
                                    “Booking Confirmation” means the confirmation from MyTurf confirming that the Booking Order has been accepted and is being processed;
                                    "Booking Order" means the order for Services that has been electronically placed on the app;
                                    "Booking Order ID" means the Services confirmation number issued by us to you upon our acceptance of the Booking Order.
                                    “App” means MyTurf app
                                    “Terms and Conditions” shall have the meaning set out at the beginning of the CONDITIONS OF USE.
                                    “Territory” means India.
                                    “Third-Party Site(s)” shall have the meaning set out at Clause 13.</p>

                                <h2 className='h2heading'>USER, USER DETAILS, ACCOUNT AND REGISTRATION</h2>
                                <h4 className='h4heading'>1) USER</h4>
                                <p className='ptag'>As a User, you may access and use the app as well as make orders or participate in tournament. If as a User, you elect not to register an account with us when access or use the app, you will not be able to use the app. If however you have elected to register an account with us and be a user (as described in Clause 2 below), you will enjoy the services only available for the user.</p>

                                <h4 className='h4heading'>1.1) User Details, Account and Registration</h4>
                                <p className='ptag'>In order to purchase any Services on the app, you need to provide certain particulars which include your name and valid email address, payment details so as to enable us to process your orders or purchases.
                                    You are required to register an account with us in order to purchase any Services on the app. As a registered user (“USER”). We however reserve the right to accept or decline a new registration and to cancel an account at any time. Please refer to our PRIVACY POLICY for more details.
                                    You must keep your account password confidential and notify us immediately once your password or account has been compromised.</p>

                                <h2 className='h2heading'>PAYMENT</h2>
                                <h4 className='h4heading'>2) Booking Order</h4>
                                <p className='ptag'>All Booking Orders submitted to MyTurf through the app are subject to our acceptance. Upon confirmation of the Booking Order, we will issue a Booking Order ID and provide you with a Booking Confirmation. The Agreement between you and MyTurf in respect of the Services ordered will be deemed completed upon payment and booking of the said Services. Until such time, we reserve the right to reject your Booking Order without providing any reason. MyTurf shall be entitled to cancel or terminate the obligations to fulfil any Booking Order, for any reason whatsoever, including no availability of Services of the Experience Provider even after a Booking Order is confirmed by, and paid for by you, with or without notice and we shall not be liable for any loss and damage to any party for such termination or cancellation. In such event, we shall refund to you the Price of the Services purchased.</p>

                                <h4 className='h4heading'>2.1) Payment Terms</h4>
                                <p className='ptag'>Unless otherwise stated, all payments to be made through the app shall be made using UPI, Net Banking, Wallet or Online payment and shall be made in accordance with such procedures as we may from time to time specify.
                                    You may also use our digital coupons (described below) during payment. Digital coupons will be issued with their own set of conditions stated therein and which are specific to each digital coupon. Please refer to the specific terms that come with the usage of digital coupons.</p>

                                <h4 className='h4heading'>2.2) Payment Processing</h4>
                                <p className='ptag'>Payments will be made via Payment Gateway provider and through the use of accepted mode of payments.</p>

                                <h4 className='h4heading'>2.3) Digital Coupons</h4>
                                <p className='ptag'>We may from time to time, offer discounts for purchases on the app through the use of promotional discount codes found on digital coupons and which may apply in respect of certain specified purchases made through the app. Only one promotional discount code found on a single digital coupon can be applied to a single order or online transaction. Once a promotional discount code is used when placing an order, the relevant digital coupon will automatically be redeemed against the total purchase amount for the relevant order. Digital coupons must be used prior to their expiration date indicated therein. Digital coupons cannot be replaced if lost, stolen, deleted, or if you should decide to cancel or return your purchase. In the event where the total purchase value is below the value of the digital coupon, the excess value of the digital
                                    coupon will not be refunded. Digital coupons will not be applicable with any other promotions that may be promoted in the Site, unless otherwise stated. If there is a conflict between the Terms and Conditions and that stated in the digital coupon, the terms and conditions stated in each digital coupon shall be final and conclusive.</p>

                                <h2 className='h2heading'>ERRORS ON OUR APP</h2>
                                <p className='ptag'>Prices and availability of Services provided are subject to change without notice. Users acknowledge and agree that the each Experience Providers solely responsible for the accuracy of their respective listing of their Services on the app. Errors in the app, which may include without limitation to errors in the Price and description of Services will be corrected when discovered, and MyTurf reserves the right to revoke any stated offer and to correct any errors, inaccuracies or omissions including after a Booking Order has been submitted and whether or not the Booking Order has been confirmed and your payment mode has been charged. If your payment mode has already been charged for the purchase and your Booking Order is cancelled, MyTurf will credit to your source payment mode account for the amount that was charged. Individual bank policies will dictate when this said amount is eventually credited to your bank account. Whilst MyTurf shall use its best endeavours to ensure the accuracy of such listings on the app and to promptly correct any errors, inaccuracies or omissions, it shall not be liable for any loss or damage arising from the same. You acknowledge and agree that such errors may sometimes occur due to software default or otherwise and is not in any way intended to mislead or misrepresent to you in any way and as such, MyTurf shall not be liable to you for any loss or damage arising from such error.</p>

                                <h1 className='h1heading'>Cancellations and Refund POLICY</h1>
                                <p className='ptag'>Cancellations are subject to the policy as set by the respective Turf Owner. You can view the cancellation policies of the respective merchant partner on their turf detail screen prior to making a booking.

                                    Cancellations can be initiated by the users themselves on your booking ticket. The refund amount due will be displayed prior to seeking confirmation of the cancellation. The refund amount will be credited back into the user’s account, to the same source through which the payment was made, within 5-7 working days, post initiating the cancellation.</p>

                                <h3 className='h3heading'>This section is used to inform users regarding our policies to fetch user’s location.</h3>
                                <p className='ptag'>If you choose to use this Service, then you agree to the fetching of the current location in relation to this policy. The current location is used for displaying the turfs in nearest to farthest (in Km) order and improving the Service. We will not share your current location with anyone except as described in this Privacy Policy.</p>
                                <p className='ptag'>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at MyTurf App unless otherwise defined in this Privacy Policy.</p>
                                <p className='ptag'>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
                                <p className='ptag'>When you register for an Account, we may ask for your contact information, including items such as full name, email address, and mobile number.</p>
                                <h2 className='h2heading'>Information Collection and Use</h2>
                                <p className='ptag'>For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to Location, Notification and Storage. The user shares the boking information by accessing the storage, gets notification when booking is successful using Notification’s permission and view the Turfs in nearest to farthest (in Km) order using Location permission. No personal information other than First name, Last name and email address is collected in this app.</p>
                                <p className='ptag'>The app does use third-party services that may collect information used to identify you.</p>
                                <ul className='uldisc'>
                                    <li className='liui'>Google Play Services.</li>
                                    <li className='liui'>Google Analytics for Firebase.</li>
                                    <li className='liui'>Firebase Crashlytics</li>
                                </ul>
                                <h2 className='h2heading'>Payment Gateway</h2>
                                <p className='ptag'>The app uses Third party payment gateway for the purpose of Booking amount payment.</p>

                                <h2 className='h2heading'>Service Providers</h2>
                                <p className='ptag'>I may employ third-party companies and individuals due to the following reasons:</p>
                                <ul className='uldisc'>
                                    <li className='liui'>To facilitate our service.</li>
                                    <li className='liui'>To provide the Service on our behalf.</li>
                                    <li className='liui'>To perform Service-related services.</li>
                                    <li className='liui'>To assist us in analyzing how our service is used.</li>
                                </ul>

                                <h2 className='h2heading'>Links to Other Sites</h2>
                                <p className='ptag'>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

                                <h2 className='h2heading'>Security</h2>
                                <p className='ptag'>We are striving to use commercially acceptable means of protecting it and all the services used to design the backend system is on Amazon Web Services with encryption of data in transit. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, we cannot guarantee its absolute security.</p>
                                <p className='ptag'>The conditions of use and privacy policy of any Third-Party Site may differ substantially from the CONDITIONS OF USE and PRIVACY POLICY that apply to your use of the app. Please review the conditions of use for all Third-Party Sites for more information about the terms and conditions that apply to your use of Third-Party services.</p>

                                <h4 className='h4heading'>1) Confidentiality of Account and password</h4>
                                <p className='ptag'>If you use the app, you are solely responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account and password. The app may only be used for lawful purposes. If your User name and/or password have been compromised, you are to immediately change your password and notify us of any unauthorized access. You also agree to immediately notify us of any of any attempted or actual unauthorized access or use of our services by third parties and/or any other breaches of security. You acknowledge and agree that we will not be in any way, liable, directly or indirectly, for any loss or damages arising from such unauthorized use or any acts or omissions on your part in maintaining the confidentiality of your account and password.</p>

                                <h4 className='h4heading'>1.1) Technical Processing and Transmission of Electronic Communications</h4>
                                <p className='ptag'>You acknowledge that the technical processing and transmission of electronic communications is necessary for your use of our services. You consent to our interception and storage of electronic communications and/or your data and acknowledge that your electronic communications will involve transmission over the Internet, and over various networks, some of which may not be owned and/or operated by us. In the context of Concept Center, electronic payment transmission refers to transactions done via credit card or ibanking or UPI.
                                    You further acknowledge that changes to your electronic communications may occur so as to conform and adapt such data to the technical requirements of connecting networks and devices. You also acknowledge that such electronic communications may be accessed by unauthorized parties when communicated across the Internet, network communications facilities, telephone, or other electronic means. As such, we are not liable for any electronic communication and/or data which are lost, altered, intercepted or stored without authorization during such transmission whatsoever across networks not owned and/or operated by us.</p>

                                <h4 className='h4heading'>1.2) Prohibited Activities</h4>
                                <p className='ptag'>You are prohibited from violating or attempting to violate the security of Concept Center, including, without limitation to carrying out any of the following prohibited activities as they infringe upon the intellectual property rights or other rights of third parties, are defamatory, illegal or may otherwise harm our goodwill, and reputation:
                                    (a) accessing data not intended for such User or logging onto a server or an account which the User is not authorised to access or accessing illegally or without authorization, the computers or networks belonging to another party, or attempting to hack into another individual's account or any activity that might be used as a precursor to an attempted system penetration (i.e. port scan, stealth scan, or other information gathering activity); or
                                    (b) using the App for unintended purposes or trying to change the behaviour of the App;
                                    (c) attempting to probe scan or test the vulnerability of a system or network or breach security or authentication measures without proper authorization;
                                    (d) attempting to interfere with service to any User, host or network, including without limitation via means of submitting a virus, worms, Trojan horses to the App, overloading, “pinging”, "flooding," "spamming," "mail bombing", “denial or service attacks” or "crashing;" or activities that disrupt the use of or interfere with the ability of third parties to effectively use the network or any connected network, system, service, or equipment; or
                                    (e) forging any TCP/IP packet header or any part of the header information in any e-mail or newsgroup posting; or
                                    (f) forging communications on behalf of the App (impersonating Concept Center) or to the App (impersonating as a legitimate User) or forging or misrepresenting message headers, whether in whole or in part, to mask the originator of the message; or
                                    (g) sending unsolicited bulk and/or unauthorized commercial messages or e-mail on behalf of Concept Center, including promotions and/or advertising of products or services, is expressly prohibited; or
                                    (h) engaging in any activity that infringes upon or misappropriates the copyright, trade marks, patents, design or any intellectual property rights of third parties whatsoever; or
                                    (i) engaging in activity that infringes upon the privacy or other personal rights of third parties; or
                                    (i) engaging in activity that infringes upon the privacy or other personal rights of third parties; or
                                    (j) using the App to advertise, transmit, store, post, display, or otherwise make available pornography or obscene speech or material; or
                                    (k) using the App to transmit or post defamatory, harassing, abusive, or threatening language or promoting physical harm or injury against any group or individual, or promoting any act of cruelty to animals; or
                                    (l) advertising, transmitting, or otherwise making available any software, program, product, or service that is designed to spam, initiation of pinging, flooding, mail bombing, denial of service attacks, and piracy of software; or (n) exporting encryption software over the Internet or otherwise; or
                                    (m) engaging in activities that are illegal, including advertising, transmitting, or otherwise making available Ponzi schemes, pyramid schemes, fraudulently charging credit cards, and pirating software or; promoting or providing instructional information about illegal activities; or
                                    (n) offering for sale any item, goods or services that breaches any applicable laws or regulations; or
                                    (o) any attempts to decompile, reverse engineer, disassemble, modify or hack the App or its application; or
                                    (p) using the App as a forwarding service to another webapp thereby promoting the App with inappropriate links, titles or descriptions; or
                                    (q) engaging in activities, whether lawful or unlawful, that we determine to be harmful to our Users, operations, reputation, goodwill, or customer relations.
                                    Violations of system or network security may result in civil or criminal liability. You agree not to use any device, software or routine or data to interfere or attempt to interfere with the proper working of the App or any activity being conducted on this App. You agree, further, not to use or attempt to use any engine, software, tool, agent, data or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search the App other than the search engine and search agents or interfaces provided by MyTurf or generally publicly available browsers.
                                </p>

                                <h4 className='h4heading'>1.3) Fraud Protection and Compliance with Law</h4>
                                <p className='ptag'>We may disclose information, including personally identifiable information, with other companies and organisations for credit fraud protection and risk reduction in accordance with applicable laws. We may also disclose any information, in our sole discretion, to comply with any applicable law, regulation, legal process or governmental request. In cases of fraud, the proper authorities will be informed and legal action will be taken.</p>

                                <h4 className='h4heading'>1.4) Data Security</h4>
                                <p className='ptag'>At Concept Center, protecting your information is a priority. To prevent unauthorised access and maintain data accuracy, we have put in place appropriate procedures to safeguard and secure the information we collect online. Please refer to the PRIVACY POLICY for more details on the collection, use and protection of personal information.</p>

                                <h2 className='h2heading'>GOVERNING LAW AND JURISDICTION</h2>
                                <p className='ptag'>These Terms and Conditions shall be governed by the Indian Laws without giving effect to any principles or conflicts of laws. Both parties irrevocably and unconditionally submit to the exclusive jurisdiction of the Indian courts.</p>
                                
                                <h2 className='h2heading'>Contacting Us</h2>
                                <p className='ptag'>If you have any questions about this Privacy Policy, the practices of the MyTurf app, or your dealings with this site, please contact us at: connect@myturf.online</p>

                                <h4 className='h4heading'>Updated on:- 12/05/2023</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Terms;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Privacypolicy  from './Privacypolicy';
import AboutUs  from './AboutUs';
import Terms from './Terms';
import Refund from './Refund';
import DeleteUser from './DeleteUser';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}/>
        <Route path='/privacy-policy' element={<Privacypolicy />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/refundpolicy' element={<Refund />} />
        <Route path='/delete-user' element={<DeleteUser />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

{/* <BrowserRouter>
<Routes>
  <Route exact path='/' component={App} />
  <Route path='/about' component={About} />
</Routes>


</BrowserRouter> */}
import React from 'react';
function AboutUs() {
    return (
        <div>
            <div class="wrapper">
                <div class="page">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                            <img src='assets/img/about-us.png' className='img-responsive255' alt='' />
                            <p/>
                                <h2 className='h2heading'>About the App</h2>
                                <p className='ptag'>Are you finding turfs in your area? Are you tired of tracking down tournaments happening around you in the city?
                                    Introducing MyTurf App. We are one of fastest growing platform of Turf Ground aggregator. With this you can find your nearest Turf with ease.
                                    App is available on both IOS and Android.
                                    We are hardcore gamers because of which we understand how technology can transform the physical gaming experience.

                                    Go on and download the app and experience the smooth ride of booking the turf in just 3 clicks.

                                    Surf your turf on MyTurf.com</p>

                                <p className='ptag'>MyTurf App helps the Sports players (Users) and Turf (ground) Owners for the following activities:</p>
                                <p className='ptag'>1. List the Turfs and provide below information about the turf:</p>
                                <ul className='uldisc'>
                                    <li className='liui'>Sports available. Ex: Cricket | Football.</li>
                                    <li className='liui'>Facilities provided by the Turf. Ex: Café | Washroom | 24 hours open | Shed facility.</li>
                                    <li className='liui'>Available slots for booking a turf.</li>
                                </ul>
                                <p className='ptag'>2. MyTurf - To display the Turfs based on user current location, notify a user after successful booking via Push Notification and share the booking (Requires Location, Notifications, Storage Permission- for fetching users’ location and accessing the file from the storage to share as a photo for the booking)</p>
                                <p className='ptag'>3. Booking Collection - Players Pays the booking amount using Payment Gateway (Third Party service)</p>
                                <p className='ptag'>4. Bookings History- Users and Turf Owners can view the Booking History and can perform cancellation and provide ratings.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
